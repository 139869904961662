@import '~material-design-icons/iconfont/material-icons.css';
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(../src/assets/fonts/Roboto/Roboto-Light.ttf) format("truetype");
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../src/assets/fonts/Roboto/Roboto-Regular.ttf) format("truetype");
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../src/assets/fonts/Roboto/Roboto-Medium.ttf) format("truetype");
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../src/assets/fonts/Roboto/Roboto-Bold.ttf) format("truetype");
}
@font-face {
  font-family: 'Solaiman Lipi';
  src: url(../src/assets/fonts/SolaimanLipi.ttf) format("truetype");
  font-display: fallback
}
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('../src/assets/fonts/Material Icons.woff2') format('woff2');
  font-display: fallback;
}

:root {
  --main-black: #2d3132;
  --main-red: #996262;
  --red-dark: #a11f1f;

  --secondary-black: #36393a;
  --secondary-red: #f12226;

  --sdp-primary: #fd8a3f;
  --sdp-light: #ffa162;
  --sdp-dark: #d97c40;
  --sdp-banner: rgba(217, 124, 64, 0.8);
  
  --live-light: #6d98c4;
  --live-primary: #5785b3;
  --live-dark: #4b7096;
  --live-banner: rgba(87, 133, 179, 0.9);
  
  --content-primary: #369936;
  --content-dark: #308830;
  --content-light: #3ca83c;
  --content-banner: rgba(54, 153, 54, 0.8);
  --content-chapHeader: #e6d6d6;
}
* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-family: 'Roboto', 'Solaiman Lipi', 'SolaimanLipi', 'Segoe UI', 'Arial', 'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
}
/* ::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background: transparent;
} */
html, body {
  /* overflow-x: hidden; */
  margin: 0;
  padding: 0;
  /* height: 100%; */
  /* width: 100%; */
}
.suspenseLoader {
  height: 100vh;
  width: 100vw;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  background-color: #fff;
  /* background-color: rgba(255, 166, 0, 0.192); */
}
.suspenseLoader img {
  width: 80px;
}
.width-full {
	width: 100%;
	float: left;
}
.width-third {
	width: 75%;
	float: left;
}
.width-half {
	width: 50%;
	float: left;
}
.width-quarter {
	width: 25%;
	float: left;
}

.chapterButtons {
  margin-bottom: 0 !important;
  padding-bottom: 15px !important;
}
.chapterButtons a .material-icons {
  padding-top: 3px;
}
.chapterButtons a span {
  display: block;
  line-height: 1.3;
  padding-bottom: 1px;
  overflow: hidden !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: inherit !important;
}
.contentSuper .chapterButtons .disabled, .contentSuper .chapterButtons .disabled * {
  color: rgb(179, 179, 179) !important;
}
body {
  background-color: #fff !important;
}
.accountOptions {
  background-color: rgb(73, 73, 73) !important;
}
.accountOptions::after {
	border-bottom-color: rgb(73, 73, 73) !important;
	border-top-color: rgb(73, 73, 73) !important;
}
.accountOptions button  {
  background-color: transparent !important;
  border: none !important;
  width: 100% !important;
  text-align: left;
}
.accountOptions .material-icons {
  font-size: 20px;
  margin-right: 10px;
}
.accountOptions button {
  outline: none;
  transition: 0.3s;
  border: none;
  color: #ffffff;
  width: 100%;
  padding: 8px 20px;
  text-align: left;
  background-color: transparent;
  font-size: 16px;
}
.accountOptions button {
  display: flex;
  align-items: center;
}
.accountOptions button:hover {
  background-color: var(--secondary-red) !important;
}
.accountOptions .active {
  background-color: var(--secondary-red) !important;
}

header .classWiseBooks {
  display: flex;
  align-items: center;
  justify-content: center;
}
header .classWiseBooks * {
  margin: 0;
  padding: 0;
  line-height: 1;
  transition: 0.3s;
  color: rgb(150, 150, 150);
}
header .logo {
	width: 220px;
}
header .headerHome {
  position: absolute;
  height: 100%;
  top: 0;
  left: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
}
header .headerHome span {
  line-height: 1.2;
  color: rgb(150, 150, 150);
  padding-left: 8px;
  padding-right: 10px;
  text-align: left;
  text-transform: uppercase;
  font-size: 14px;
}
header .headerHome-en span {
  font-size: 12px;
}
header .headerHome a, header .headerHome div {
  padding: 0 10px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
header .headerHome .material-icons {
  font-size: 28px;
  transition: 0.3s;
  color: rgb(150, 150, 150);
}
header .headerHome a:hover span, header .headerHome div:hover span {
  color: rgb(255, 255, 255);
}
header .headerHome a:hover .material-icons, header .headerHome div:hover .material-icons {
  transform: scale(1.1);
  color: rgb(255, 255, 255) !important;
}
header .classWiseBooks .viewAllBooks {
  text-transform: uppercase;
  padding-left: 10px;
  font-size: 13px;
}
header .classWiseBooks:hover i {
  transform: scale(1.1);
  animation-name: rotBook;
  animation-duration: 0.5s;
  animation-iteration-count: 2;
}
header .classWiseBooks:hover * {
  color: #fff;
}
header .classWiseBooks:hover {
  border-color: #fff;
}

.contentHome .headerHome a:first-of-type, .contentHome .headerHome div, .sdpHome .headerHome a:first-of-type, .sdpHome .headerHome div, .dlmsHome .headerHome a:first-of-type, .dlmsHome .headerHome div, .pepsHome .headerHome a:first-of-type, .pepsHome .headerHome div {
  display: none !important;
}

.languageSelectorHome button {
  outline: none !important;
  border: none;
  padding: 0;
  background-color: transparent;
  font-size: 15px;
  margin: 0 5px
}

.languageSelectorHome span {
  font-size: 15px;
  /* font-weight: bold; */
}

.languageSelectorHome .active {
  font-weight: bold;
  color: rgb(8, 141, 8);
}

.languageSelectorHome hr {
  width: 90px;
  margin: 0 auto;
  border-color: orange;
  border-width: 3px;
  margin-bottom: 10px;
  margin-top: 15px;
}

.footer {
	background-color: rgb(255, 255, 255);	
	width: 100%;
	text-align: center;
	font-size: 15px;
	color: rgb(0, 0, 0, 0.7);
  line-height: 1.2;
	height: 60px;
	display: flex;
	align-items: center;
	padding-left: 30px;
	padding-right: 30px;
	justify-content: space-between;
	border-top: thin solid rgb(235, 235, 235);
}
.footer p {
  margin: 0;
  padding: 0;
}
.footer-cms {
  justify-content: flex-end;
  border: none;
  background-color: rgba(255, 255, 255, 0.3);
}
.footer p:last-of-type {
	margin-top: 3px;
}
.footer a {
	text-decoration: none;
	font-size: inherit;
	color: inherit;
}
.footer img {
	filter: invert(30%);
  width: 92px;
  margin-left: -3px;
}



/* ONLINE EXAM */
.onlineExamTitle {
	border: none;
	background-color: rgb(240, 228, 228);
	padding: 15px 25px 25px 25px;
	display: block;
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
	color: black;
	position: relative;
}
.onlineExamTitle:after {
	content: '';
	position: absolute;
	width: calc(100% - 8px);
	background-color: #fff;
	height: 15px;
	bottom: 0;
	left: 4px;
	margin-bottom: -4px;
	z-index: 99;
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
}
.onlineExamStep1 {
	border: 4px solid rgb(240, 228, 228);
	padding: 25px;
	padding-bottom: 0;
	border-bottom-left-radius: 15px;
	border-bottom-right-radius: 15px;
	/* border-radius: 15px; */
}
.question-child .question-options-4 {
	width: 50%;
	padding: 3px 10px;
	font-size: 17px;
}
.onlineExamQuestion {
	font-size: 18px;
}
.instruction {
	line-height: 1.3;
}
.onlineExamStarted {
	font-size: 18px;
	/* padding: 20px; */
	border-radius: 15px;
	background-color: rgb(188, 224, 199);
	margin-bottom: 30px;
	overflow: hidden;
	position: fixed;
	top: 100px;
	z-index: 100;
	display: block;
	width: calc(100vw - 310px);
}
.onlineExamStarted p {
	padding: 0 15px;
}
.onlineExamStarted .examStarted {
	padding: 15px 20px;
	background-color: rgb(92, 173, 68);
	color: #fff;
}
.onlineExamStarted .time {
	line-height: 1;
	padding: 20px;
	padding-top: 10px;
	font-size: 40px;
	/* border: thin dashed red; */
	display: inline-block;
}
.onlineExamStarted .time label {
	font-size: 17px;
	display: block;
	margin-bottom: 5px;
}
.onlineExamStep2 {
	padding-top: 120px;
}
.examTimer {
	display: flex;
	justify-content: space-between;
}
.onlineExamQuestionChild {
	display: flex;
	flex-direction: row;
	overflow: hidden;
	padding: 0;
	border: none;
}
.questionNumber span {
	display: block;
	font-size: 20px;
}
.questionNumber {
	display: flex;
	flex-direction: column;
	padding-top: 20px;
	/* justify-content: center; */
	align-items: center;
	width: 70px;
	min-width: 70px !important;
	font-size: 30px;
	margin-right: 10px;
	margin-bottom: 0 !important;
	background-color: rgb(173, 215, 231);
}
.onlineExamTimeUp {
	font-size: 18px;
	padding: 25px;
	background-color: rgba(216, 0, 0, 0.767);
	border-radius: 15px;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	position: fixed;
	height: 150px;
	width: calc(100vw - 310px);
	top: calc(50vh - 100px);
	z-index: 99;
}
.onlineExamTimeUp p {
	line-height: 1.3;
}
.blur-3 {
	pointer-events: none !important;
	filter: blur(6px);
	-webkit-filter: blur(6px);
}
.blur-3 * {
	pointer-events: none !important;
}

@media only screen and (max-width: 900px) {
  .footer-cms {
    justify-content: center
  }
}
@media only screen and (max-width: 750px) {
  header .headerHome span {
    display: none;
  }
}
@media only screen and (max-width: 575px) {
	.footer {
		display: block;
		width: 100%;
		height: unset;
		padding: 15px;
	}
}
@media only screen and (max-width: 450px) {
  header .headerHome {
    display: none;
  }
}

@media only screen and (max-width: 900px) {
  header .userName {
    display: none;
  }
  header .accountContainer {
    margin-right: 10px;
    /* display: none; */
  }
  header .responsiveMenuIcon {
    /* border: thin solid black; */
    padding-right: 10px;
    display: block;
  }
  header .logoContainer {
    background-color: unset;
    width: unset;
    padding-left: 75px;
  }
  header .accountOptions {
    /* top: 70px; */
    right: 45px;
  }
  .contentHome header .logo, .sdpHome header .logo, .dlmsHome header .logo, .pepsHome header .logo {
    width: 170px;
  }
  header .logo {
    width: 170px;
  }
  header .headerHome {
    left: 250px;
  }
}
@media only screen and (max-width: 750px) {
  header .headerHome span {
    display: none;
  }
}
@media only screen and (max-width: 550px) {
  header .classWiseBooks {
    left: 260px;
    display: none !important;
  }
  header .profilePic {
    display: none;
  }
}
@media only screen and (max-width: 450px) {
  header .headerHome {
    display: none;
  }
  .accountOptions {
    width: calc(100% - 20px) !important;
    left: 10px !important
  }
  .accountOptions::after {
    border: none !important;
  }
}